.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;

}

td {
  border: 1px solid black;
  padding: 8px;
}

th {
  border: 1px solid black;
  padding: 8px;
}

.Button {
  background-color: rgba(250, 235, 215, 0);
  color: black;
  font-size: 15px;
  padding: 5px 30px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
@media (min-width: 768px) {
  .loginBtn {
    font-size: 2em; /* increase font size on larger screens */
    padding: 1.5em; /* increase padding on larger screens */
    width: 50%; /* decrease width on larger screens */
  }
}

.loginBtn {

  /* display: flex;
  justify-content: center; */
  background-color:gray;
  color: black;
  font-size: 1.5em; /* change this to em for responsive font size */
  font-weight: bold;
  /* font-style: italic; */
  border-radius: 4px;
  font-family: sans-serif;
  /* margin: 400px 800px; */
  cursor: pointer;
  /* border: 1px solid #1063a9; */
  padding: 0.15em; /* change this to em for responsive padding */
  width: 10%; /* change this to a percentage for responsive width */
}
.loginBtn:hover {
  background-color: rgba(0.8, 255, 255, 255); /* change this to the color you want */
}

.container {
  position: relative;
}

.version {
  font-size: 15px;
  font-weight: bolder;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 5px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;

}

.logoutBtn {
  background-color: gray;
  color: black;
  font-size: 15px;
  font-family: sans-serif;
  padding: 3px 30px;
  border-radius: 4px;
  margin: 10px 0px;
  cursor: pointer;
  /* border: 1px solid #1063a9; */
  padding: 7px;
  width: 10%;
  font-weight: bold;
  margin-left: 5px;
}

.createBtn {
  background-color: rgba(250, 235, 215, 0);
  color: black;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.existingBtn {
  background-color: rgba(250, 235, 215, 0);
  color: black;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  margin-right: 50px;

}


.Back {
  line-height: 24px;
  background-color:gray;
  color: black;
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 50px;
  /* margin-right: 100px; */
  position: absolute;
  top: 0;
  right: 0;
  /* border: 1px solid #1063a9; */
  padding: 7px;
  width: 10%;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  
}

.uploadfile{
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  width: 25%;
  margin: 0 auto;
  background-color: #2ebbca;
}

.cleartable {
  float: right;
  background-color: gray;
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  /* border: 1px solid gray; */
  padding: 5px;
  width: 20%;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
}

.download {
  font-weight: bold;
  background-color: gray;
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  /* border: 1px solid gray; */
  margin-right: 10px;
  padding: 5px;
  width: 6%;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

body {
  background-color:white;
  background-image: linear-gradient(to bottom, rgba(0.8, 255, 255, 255), rgba(255, 255, 255, 0.8)), url('../images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.form {
  background:#2ebbca;
  border: 1px solid #dedede;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 50px;
}


.input {
  border: 1px solid black;
  padding: 7px;
  width: 80%;
  border-radius: 5px;
  box-sizing: border-box;
  background:#2ebbca;
}

.label {
  text-align: left;
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 5px;

}

.submitButton {
  background-color: gray;
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  margin: 20px 10px;
  /* border: 1px solid #1063a9; */
  padding: 7px;
  width: 80%;
  
}


.dropdown {
  background-color: white;
  border: 2px solid #1063a9;
  border-radius: 4px;
  font-size: 13px;
  display: inline-block;
  font: sans-serif;
  padding: 0.5em 3.5em 0.5em 1em;
}

.Upload {
  background-color: grey;
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  margin: 10px 30px;
  margin-top: 10px;
  margin-bottom: -40px;
  /* border: 1px solid black; */
  padding: 7px;
  width: 8%;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}


.select-container {
  width: 200px;
  margin-bottom: 20px;
}

.select__control {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: none;
}

.select__menu {
  font-size: 16px;
}

.select__option {
  padding: 8px 16px;
}

.select__option--is-selected {
  background-color: #007bff;
  color: #fff;
}

.select__multi-value {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.select__multi-value__label {
  font-size: 16px;
  color: #333;
}

.select__multi-value__remove {
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
}